import React from 'react'
import { Container, Typography, Paper, InputBase, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import SearchSVG from '../../images/searchBar.inline.svg'
import LoaderButton from '../Buttons/LoaderButton'
import useSearchForm from '../../hooks/useSearchForm'

interface Props {
  searchResult?: string
  variant?: string
}

const SearchSection: React.FC<Props> = ({ searchResult, variant }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const prefix = variant === 'samples' ? 'category/resume-example' : null

  const {
    htmlSearchAction,
    searching,
    searchQuery,
    handleUpdateSearchQuery,
    handleSearchSubmit,
  } = useSearchForm({ prefix: prefix })

  return (
    <Box className={classes.main}>
      <Container className={classes.container} disableGutters>
        <Box className={classes.innerContainer}>
          <Typography className={classes.header} variant="h1">
            {t(
              variant === 'samples' ? 'search.headerSamples' : 'search.header'
            )}
          </Typography>
          <form
            className={classes.root}
            onSubmit={handleSearchSubmit}
            action={htmlSearchAction}
          >
            <Paper className={classes.searchContainer}>
              <Search className={classes.searchIcon} />
              <InputBase
                type="search"
                className={classes.searchField}
                placeholder={t(
                  variant === 'samples' ? 'search.inputSamples' : 'search.input'
                )}
                name="s"
                inputProps={{
                  'aria-label': `${t(
                    variant === 'samples'
                      ? 'search.inputSamples'
                      : 'search.input'
                  )}`,
                }}
                value={searchQuery}
                onChange={handleUpdateSearchQuery}
              />
            </Paper>
            <LoaderButton
              type="submit"
              className={classes.searchButton}
              aria-label="search"
              loading={searching}
            >
              <Typography variant="h3">{t('search.cta')}</Typography>
            </LoaderButton>
          </form>
          <Typography className={cx(classes.searchSub)}>
            {searchResult}
          </Typography>
          <Box className={classes.imageContainer}>
            <SearchSVG />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  main: {
    overflow: 'hidden',
    display: 'flex',
    maxWidth: '100%',
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.dark,
    padding: '6em 6% 3.75em 6%',
    [theme.breakpoints.down('md')]: {
      padding: '6em 6% 3.75em 6%',
    },
    '@media (min-width: 1456px)': {
      padding:
        '6em calc((100% - 91rem)/2 + 5.5rem) 3.75em calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  header: {
    marginBottom: '2.625rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
      width: '100%',
      textAlign: 'center',
    },
  },
  container: {
    maxWidth: '100%',
    color: theme.palette.background.default,
    marginLeft: 0,
  },
  innerContainer: {
    width: '100%',
    position: 'relative',
    maxWidth: '100%',
  },
  root: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    padding: '1em',
    backgroundColor: theme.palette.background.default,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  searchIcon: {
    color: theme.palette.primary.main,
  },
  searchField: {
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchButton: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: '1em 3.7em',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  searchSub: {
    fontSize: '.92em',
    lineHeight: 1.28,
    marginTop: '.75em',
  },
  imageContainer: {
    maxWidth: '30em',
    flex: 1,
    position: 'absolute',
    right: '-1%',
    bottom: '-140%',
    [theme.breakpoints.down('lg')]: {
      right: '-15%',
      maxWidth: '25em',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export default SearchSection
