import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect, useState } from 'react'

import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

import NotFound from '@talentinc/gatsby-theme-ecom/components/Errors/NotFound'
import SearchSection from '@talentinc/gatsby-theme-ecom/components/LandingPage/SearchSection'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'

import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'

const NotFoundPageTemplate: React.FC<PageProps<PageDataContextType>> = (
  props
) => {
  const { t } = useTranslation()

  // If we 404 on a blog page, show the blog navigation.
  // You might look at this and wonder "Why not just set the showBlogNavigation
  // prop on the footer with this check and skip the useEffect?". If only it was
  // so simple. I attempted this on the first pass and it worked great in dev,
  // but once it was in production mode, it would absolutely mangle the styles
  // in such a way that each component got the styles of the component after it.
  // Truely haunted stuff, let me tell ya. Oh the joys of SSR.
  const [showBlogNavigation, setShowBlogNavigation] = useState(false)
  useLayoutEffect(
    () =>
      setShowBlogNavigation(
        props.location.pathname.startsWith(`/${props.data.brand.blogPrefix}`)
      ),
    [props.location.pathname, props.data.brand.blogPrefix]
  )

  return (
    <Layout pageTitle={t('notFound.pageTitle')} pageData={props.data}>
      <HeaderV2 showBlogNavigation={showBlogNavigation} />
      <SearchSection />
      <NotFound />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPage($brandName: String!) {
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPageTemplate
